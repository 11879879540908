<template>
    <section class="gardenManagementPage">
        <g-breadcrumb bread="添加视频" />
        <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="公告标题" label-width="100px">
                    <el-input v-model="noticeTitle" maxlength="35" placeholder="请输入分类名称" style="width: 210px;" />
                </el-form-item>
            </div>

            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="公告内容" label-width="100px">
                    <Tinymce :height="200"  v-model="tiny.value"></Tinymce>
                </el-form-item>
            </div>
            
            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="公告缩略图" label-width="100px" v-if="piclist==''">
                    <uploadPics @fileList="fileListClick" :file-list.sync="fileLists" :limit="1"  @moveList="moveFileClick">
                    </uploadPics>
                </el-form-item>
                <el-form-item label="公告缩略图" label-width="100px" v-else>
                    <div >
                        <el-image class="my-img" :src="piclist"  style="width:100px;height:80px;border:1px solid #ccc;border-radius:4px;"> </el-image>
                        <p class="m-left25 " @click='moveFileClick'>删除</p>
                        <!-- <i class="el-icon-delete f-20 p-a col-6" style="top:-10px;right:-15px;" @click='moveFileClick'></i> -->
                    </div>
              

            </el-form-item>
            </div>
        
        

            <div class="dis-flex flex-x-start flex-y-start">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="是否轮播" label-width="100px">
                    <el-radio v-model="radio" label="0">否</el-radio>
                    <el-radio v-model="radio" label="1">是(小程序首页轮播图)</el-radio>
                </el-form-item>
            </div>
            <div class="dis-flex flex-x-start flex-y-start" v-if="radio == '1'">
                <p class="m-top15 col-red">*</p>
                <el-form-item label="轮播图750*372" label-width="100px" v-if="carouselUrl==''">
                    <uploadPics @fileList="bannerListClick" :file-list.sync="bannerLists" :limit="1" @moveList="bannerFileClick"></uploadPics>
                </el-form-item>
                <el-form-item label="轮播图750*372" label-width="100px" v-else>
                    <div class="p-r">
                        <el-image class="my-img" :src="carouselUrl" style="width:100px;height:80px;border:1px solid #ccc;border-radius:4px;"> </el-image>
                        <p  class="m-left25"  @click='bannerFileClick'>删除</p>
                        <!-- <i class="el-icon-delete f-20 p-a col-6" style="top:-10px;right:-15px;" @click='bannerFileClick'></i> -->
                    </div>
                    
                </el-form-item>
            </div>
        </el-form>
        



        <el-button  @click="cancelClicks">取消</el-button>
        <el-button type="primary" @click="buttonClick">提交</el-button>
 
        
    </section>

</template>

<script>
import Tinymce from "./tinymce";
import uploadPics from '@/components/Upload/uploadPics.vue'
import uploadList from '@/components/Upload/uploadList.vue'
import {
    addPxNotice,
    queryPxNotice,
    updatePxNotice
} from '@api/bx/api_contentManagement';
import UeditorWrap from "vue-ueditor-wrap";
export default {
    components: { Tinymce, uploadPics, uploadList },
    data() {

        return {
            limit: 1,
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },
            total: 0,
            tableData: [],
            // typeList: [],
            // dicCode: null,
            // addCode: null,
            // addItem: {},
            // dicName: '',
            // isEditor: false,
            // editorRow: {},
            // text: '',
            // dishList: [],
            // dishName: '',
            radio: '0',
            piclist: '',
            carouselUrl: '',
            noticeTitle: '',
            editor: '0',
            id: '',
            fileLists: [],
            bannerLists: []
        }
    },
    created() {
        this.editor = this.$route.query.editor
        this.id = this.$route.query.id
        if (this.editor == '1') {
            this.queryPxNotice()
        }else{
       
        }
   
        // console.log(this.editor)


    },
    methods: {
        moveFileClick(msg) {
            // console.log(11111)
            this.piclist = ''
            this.fileLists=[]
        },
        bannerFileClick(msg) {
            // console.log(2222)
            this.carouselUrl = ''
            this.bannerLists=''
        },
        // 保存富文本内容-保存字符串
        add() {
            const params = `this.tiny?.value`
        },


        fileListClick(msg) {
            this.piclist = msg.rootPath
            // console.log(msg)
        },
        bannerListClick(msg) {
            this.carouselUrl = msg.rootPath
            // console.log(msg)
        },
        cancelClicks(){
            this.$router.push('/bxPxNoticeMenu/dcPxNoticeList')
            
        },
        buttonClick() {
            if (this.noticeTitle == '') {
                this.$message.warning('请填写公告名称')
                return
            }
            if (this.tiny.value == undefined) {
                this.$message.warning('请填写公告简介')
                return
            }
            if (this.piclist == '') {
                this.$message.warning('请上传公告缩略图')
                return
            }
            if (this.radio == '1' && this.carouselUrl =='') {
                this.$message.warning('请上传轮播图')
                return
            }
            if (this.editor == '0') {
                // return
                //首次提交
                addPxNotice({
                    noticeTitle: this.noticeTitle,
                    noticeContent: this.tiny.value,
                    noticeImg: this.piclist,
                    isCarousel: parseInt(this.radio),
                    carouselUrl: this.radio == '1' ? this.carouselUrl : ''

                }).then(res => {
                    this.$message.success('上传成功')
                    this.$router.push('/bxPxNoticeMenu/dcPxNoticeList')

                })
            } else {
                //编辑提交
                updatePxNotice({
                    noticeTitle: this.noticeTitle,
                    noticeContent: this.tiny.value,
                    id: this.id,
                    noticeImg: this.piclist,
                    isCarousel: parseInt(this.radio),
                    carouselUrl: this.radio == '1' ? this.carouselUrl : ''

                }).then(res => {
                    this.$message.success('更新成功')
                    this.$router.push('/bxPxNoticeMenu/dcPxNoticeList')


                })

            }

        },
        //文章复现
        queryPxNotice() {
            queryPxNotice({
                id: this.id
            }).then(res => {
                let fileList = []
                this.noticeTitle = res[0].noticeTitle
                this.tiny.value = res[0].noticeContent
                this.radio = res[0].isCarousel
                if (res[0].noticeImg !== '') {
                    this.fileLists = [
                        {
                            name: '',
                            url: res[0].noticeImg
                        }
                    ]
                    this.piclist = res[0].noticeImg
                }
                if (res[0].carouselUrl !== '') {
                    this.bannerLists = [
                        {
                            name: '',
                            url: res[0].carouselUrl
                        }
                    ]
                    this.carouselUrl = res[0].carouselUrl
                }



            })
            // console.log(this.tiny.value)
        },


        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },

   



    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

